import React, { useState } from "react";

import { post_auth } from './API';
import { set_me } from './me';

function Auth(props) {
	let [ username, set_username ] = useState("");
	let [ password, set_password ] = useState("");
	let [ error, set_error ] = useState(null);

	const input_change = (setter) => (e) => setter(e.target.value);

	const click = (e) => {
		e.preventDefault();
		post_auth(username, password).then((e) => {
			set_me(e.data.Identity);
			props.goto_id("select");
		}).catch((e) => {
			set_error("Invalid username or password");
		});
	};

	return (
		<div className="h-100 d-flex align-items-center justify-content-center">
			<form className="form-group">
				<div>
				<label>Username<input type="text" className="form-control" name="username" value={username} onChange={input_change(set_username)}/></label>
				</div>

				<div>
				<label>Password<input type="password" className="form-control" name="password" value={password} onChange={input_change(set_password)}/></label>
				</div>

				<div>
				<input type="submit" className="form-control" value="Login" onClick={click}/>
				</div>

				<div className="errors">
				{error}
				</div>
			</form>
		</div>
	);
}

export default Auth;
