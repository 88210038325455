import axios from 'axios';

import { must_get_me } from './me';

const BASE_API = process.env.REACT_APP_BASE_API || "https://zwedit.com";

const build_url = (path, base) => (base?base:BASE_API) + "/a" + path;

const uGET = (path, params, base) => axios.get(build_url(path, base), {params: {data: JSON.stringify(params || {})}});
const uPOST = (path, params, base) => axios.post(build_url(path, base), (params || {}));
const inject_ident = (param) => {
	if (!param) param = {};
	param.Identity = must_get_me();
	return param;
};
const GET = (path, params, base) => uGET(path, inject_ident(params), base);
const POST = (path, params, base) => uPOST(path, inject_ident(params), base);

const get_auth = (identity) => uGET("/auth", identity);
const post_auth = (username, password) => uPOST("/auth", {username, password});
const post_new_password = (old_password, new_password) => POST("/new_password", {OldPassword: old_password, NewPassword: new_password});

const get_trinfo_targets = () => GET("/trinfo_targets");
const get_trinfo_target = (key) => GET("/trinfo_target", {Key: key});
const post_trinfo_commit = (key, payload) => POST("/trinfo_commit", {Key: key, Payload: payload});
const get_trinfo_commit_status = (status_key) => GET("/trinfo_commit_status", {StatusKey: status_key});
const post_trinfo_deletion = (project, branch) => POST("/trinfo_deletion", {Project: project, Branch: branch});

const get_big_text_projects = () => GET("/big_text_projects");
const post_big_text_new_key = (project, key) => POST("/big_text_new_key", {Project: project, Key: key});
const post_big_text = (project, key, language, contents) => POST("/big_text", {Project: project, Key: key, Language: language, Contents: contents});
const post_big_text_deletion = (project, key) => POST("/big_text_deletion", {Project: project, Key: key});

const get_intrinfo_targets = () => GET("/intrinfo_targets");
const get_intrinfo_target = (key) => GET("/intrinfo_target", {Key: key});
const post_intrinfo_patch = (key, payload) => POST("/intrinfo_patch", {Key: key, Payload: payload});
const post_intrinfo_deletion = (key) => POST("/intrinfo_delete", {Key: key});

export {
	// auth
	get_auth,
	post_auth,
	post_new_password,

	// trinfo (/traks)
	get_trinfo_targets,
	get_trinfo_target,
	post_trinfo_commit,
	get_trinfo_commit_status,
	post_trinfo_deletion,

	// big text
	get_big_text_projects,
	post_big_text_new_key,
	post_big_text,
	post_big_text_deletion,

	// intrinfo (also traks)
	get_intrinfo_targets,
	get_intrinfo_target,
	post_intrinfo_patch,
	post_intrinfo_deletion,
};
