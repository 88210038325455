function array2set(xs) {
	var set = {};
	for (var x of xs || []) set[x] = true;
	return set;
}

const unpack = x => (x || "").split(/[ ,]+/);
const pack = xs => (xs || []).join(" ");

function flatten_rec(x) {
	if (typeof x === "string") {
		return unpack(x);
	} else if (typeof x === "object") {
		var xs = [];
		for (const a of x) xs = xs.concat(flatten_rec(a));
		return xs;
	} else {
		return [];
	}
}

function flatten_all() {
	return flatten_rec(arguments);
}

function classes() {
	return pack(flatten_all(arguments));
}

const has_value = (v) => (v !== undefined && v !== null);

const deep_copy = (o) => JSON.parse(JSON.stringify(has_value(o) ? o : null));

const get_language_icon_svg = (language) => {
	const languages_we_have_icons_for = array2set(["da", "de", "en", "fr", "nl", "no", "sv"]);
	if (!languages_we_have_icons_for[language]) return null;
	return "gfx/lang-" + language + ".svg";
};

const download = (filename, text) => {
	let em = document.createElement('a');
	em.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
	em.setAttribute('download', filename);
	em.style.display = 'none';
	document.body.appendChild(em);
	em.click();
	document.body.removeChild(em);
};

const on_input_change = (setter) => (e) => setter(e.target.value);
const on_checkbox_change = (setter) => (e) => setter(e.target.checked);

export {
	array2set,
	classes,
	has_value,
	deep_copy,
	get_language_icon_svg,
	download,
	on_input_change,
	on_checkbox_change,
};
