import React, { useState } from "react";

const Context = React.createContext();

const Inner = (props) => {
	return React.Children.map(props.children, (c) => React.cloneElement(c, {...props.c, goto_id: props.goto_id, arg: props.arg }));
};

const Outer = (props) => {
	let default_id = props.default;

	const ids = React.Children.map(props.children, (c) => {
		if (!React.isValidElement(c)) throw new Error("invalid component seen");
		if (c.type !== Inner) throw new Error("children of <Routish.Outer> must be <Routish.Inner>");
		let id = c.props.id;
		if (!id) throw new Error("<Routish.Inner> must have have id prop");
		return id;
	});

	let default_index = ids.indexOf(default_id);
	if (default_index === -1) default_index = 0;

	let [ active_index, set_active_index ] = useState(default_index);
	let [ arg, set_arg ] = useState(null);

	let goto_id = (id, arg) => {
		let new_index = ids.indexOf(id);
		if (new_index === -1) throw new Error("unknown id: " + id);
		set_active_index(new_index);
		set_arg(arg);
	};

	let child = props.children[active_index];
	if (!React.isValidElement(child)) throw new Error("invalid child");

	const ctx = { goto_id };

	return  (
		<Context.Provider value={ctx}>
			{ React.cloneElement(child, { ...child.props, goto_id, arg }) }
		</Context.Provider>
	)
};

export default {
	Outer,
	Inner,
	Context,
};
