import React, { useState, useEffect, useContext } from "react";

import {
	get_trinfo_targets,
	get_big_text_projects,
	post_big_text_new_key,
	post_big_text_deletion,
	post_trinfo_deletion,
	post_new_password,
	get_intrinfo_targets,
	get_intrinfo_target,
	post_intrinfo_patch,
	post_intrinfo_deletion,
} from './API';
import { AR, Loading, DeleteButton } from './components';
import { get_language_icon_svg } from './util';
import Routish from './Routish';
import { set_me } from './me';

const Trinfos = (props) => {
	let [ trinfo_targets, set_trinfo_targets ] = useState(null);

	useEffect(() => {
		get_trinfo_targets().then(t => {
			set_trinfo_targets(t.data);
		});
	}, []);

	if (trinfo_targets === null) {
		return <Loading/>;
	}

	let get_project_from_key = (key) => key.split(":")[0];

	let projects = {};
	if (trinfo_targets !== null) {
		for (const t of trinfo_targets) {
			let p = get_project_from_key(t.Key);
			projects[p] = true;
		}
	}
	projects = Object.keys(projects);
	projects.sort();

	let tables = [];
	for (const project of projects) {
		let rows = trinfo_targets.map(x => {
			const key = x.Key;
			if (get_project_from_key(key) !== project) return null;
			const is_main = key.endsWith(":main") || key.endsWith(":master");
			let branch = key.split(":").slice(1).join(":");
			let extra = null;
			if (is_main) {
				extra = <span className="live">LIVE</span>;
			}
			let extrarerer = null;
			if (!is_main) {
				extrarerer = ((project, branch) => {
					const do_delete = () => {
						post_trinfo_deletion(project, branch).then(r => {
							window.location.reload();
						});
					};
					return <DeleteButton on_delete={do_delete}/>
				})(project, branch);
			}
			const arg = { type: "trinfo", key };
			return (
				<tr key={key}>
					<td><AR link="trinfo_editor" arg={arg}>{branch}</AR>{extra}</td>
					<td style={{fontSize: "0.75em"}}>{new Date(1000*x.LastModified)+""}</td>
					<td className="trinfo_func">{extrarerer}</td>
				</tr>
			);
		}).filter(x=>x);
		tables.push(
			<table key={project} className="table prjtbl">
				<thead>
					<tr>
						<th>Project: <span className="project_label">{project}</span></th>
						<th>Modified</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		);
	}

	return tables;
};

const Intrinfos = (props) => {
	let [ intrinfo_targets, set_intrinfo_targets ] = useState(null);
	useEffect(() => {
		get_intrinfo_targets().then(t => {
			set_intrinfo_targets(t.data);
		});
	}, []);

	let elements = [];

	if (intrinfo_targets !== null && intrinfo_targets.length > 0) {
		let rows = [];
		for (const t of intrinfo_targets) {
			const key = t.Key;
			const arg = { type: "intrinfo", key };
			const do_delete = ((key) => () => {
				post_intrinfo_deletion(key).then(r => {
					window.location.reload();
				});
			})(key);
			rows.push(
				<tr key={key}>
					<td><AR link="trinfo_editor" arg={arg}>{key}</AR></td>
					<td style={{fontSize: "0.75em"}}>{new Date(1000*t.Created)+""}</td>
					<td style={{fontSize: "0.75em"}}>{t.Modified > 0 ? (new Date(1000*t.Modified)+"") : "n/a"}</td>
					<td className="trinfo_func"><DeleteButton on_delete={do_delete}/></td>
				</tr>
			);
		}
		elements.push(
			<table key="intrinfo" className="table prjtbl">
				<thead>
					<tr>
						<th>File</th>
						<th>Created</th>
						<th>Modified</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		);
	}

	return elements;
};

const NewBigText = (props) => {
	let [ working, set_working ] = useState(false);
	let [ open, set_open ] = useState(false);
	let [ new_key, set_new_key ] = useState("");

	if (working) return <span>...</span>;

	if (!open) {
		const do_open = () => set_open(true);
		return <button className="btn btn-primary" onClick={do_open}>+</button>;
	} else {
		const do_create = () => {
			post_big_text_new_key(props.k, new_key.trim()).then(() => {
				set_working(false);
				props.refresh();
			});
			set_working(true);
			set_open(false);
			set_new_key("");
		};
		const do_cancel = () => {
			set_open(false);
			set_new_key("");
		};
		const do_change = (e) => {
			set_new_key(e.target.value);
		};
		return (
			<span>
			<input placeholder="type new text key" value={new_key} onChange={do_change}/>
			<button className="btn btn-primary" disabled={new_key.length===0} onClick={do_create}>Create</button>
			<button className="btn btn-secondary" onClick={do_cancel}>Cancel</button>
			</span>
		);
	}
};

const LangEdit = (props) => {
	let { lang, meta, onEdit } = props;
	let title = "Lang: " + lang + " // ";
	if (meta) {
		title += "Size: " + meta.Size + " // Last modified: " + (new Date(meta.LastModified*1000));
	} else {
		title += "Does not yet exist";
	}

	const style = meta.Size === 0 ? {border: "1px dashed #ccc"} : {border: "1px solid #ccc"};

	let src = get_language_icon_svg(lang);
	let content;
	if (src === null) {
		content = <span>{lang}</span>;
	} else {
		const sz = 15;
		content = <img alt={lang} className="" width={sz} height={sz} src={src}/>
	}
	const click = () => {
		if (onEdit) onEdit();
	};
	return <button className="btn btn-outline-secondary py-1 px-2 me-2" style={style} title={title} onClick={click}>{content}</button>;
};


const BigTexts = (props) => {
	let [ big_text_projects, set_big_text_projects ] = useState(null);
	let [ refresh_iteration, set_refresh_iteration ] = useState(0);

	const do_refresh = () => {
		set_refresh_iteration(1 + refresh_iteration);
	};

	useEffect(() => {
		set_big_text_projects(null);
		get_big_text_projects().then(t => {
			set_big_text_projects(t.data);
		});
	}, [refresh_iteration]);

	const router = useContext(Routish.Context);

	let contents = <Loading/>;
	if (big_text_projects !== null) {
		let list = [];
		for (const p of big_text_projects) {
			let languages = p.Languages;
			let tblbody = [];
			for (const t of p.Targets) {
				let links = [];
				for (const lang of languages) {
					let meta = t.Languages[lang];
					const do_edit = ((project, key, language) => () => {
						let arg = {
							fetch_url_prefix: p.FetchURLPrefix,
							project,
							key: [key, language]
						};
						router.goto_id("big_text_editor", arg);
					})(p.Name, t.Key, lang);
					links.push(
						<LangEdit key={lang} lang={lang} meta={meta} onEdit={do_edit}/>
					);
				}

				let do_delete = ((project, key) => () => {
					post_big_text_deletion(project, key).then(r => {
						do_refresh();
					});
				})(p.Name, t.Key);

				links.push(
					<DeleteButton key="DELETE" on_delete={do_delete}/>
				);
				tblbody.push(
					<tr key={t.Key}>
						<td className="py-3 align-middle">
							<tt>{t.Key}</tt>
						</td>
						<td className="py-3 align-middle">
							{links}
						</td>
					</tr>
				);
			}
			list.push(
				<div key={p.Name}>
					<h2><tt>{p.Name} <NewBigText k={p.Name} refresh={do_refresh}/></tt></h2>
					<table className="table prjtbl ms-3">
					<thead>
						<tr>
						<th className="pb-3">Key</th>
						<th className="pb-3">Edit</th>
						</tr>
					</thead>
					<tbody>
						{tblbody}
					</tbody>
					</table>
				</div>
			);
		}

		contents = <div>{list}</div>
	}

	return (
		<div>
		{contents}
		</div>
	);
};

const LogOut = (props) => {
	const router = useContext(Routish.Context);
	const do_logout = () => {
		set_me(null);
		router.goto_id("auth");
	};
	return <button className="btn btn-warning form-control-sm me-2" onClick={do_logout}>Logout</button>
};

const ChangePassword = (props) => {
	let [ state, set_state ] = useState(0);
	let [ old_password, set_old_password ] = useState("");
	let [ new_password, set_new_password ] = useState("");
	let [ new_password_again, set_new_password_again ] = useState("");
	let [ error, set_error ] = useState(null);

	const mk_change = (change_fn) => (e) => change_fn(e.target.value);
	const do_close = () => {
		set_state(0);
		set_old_password("");
		set_new_password("");
		set_new_password_again("");
		set_error("");
	};

	if (state === 0) {
		const do_click = () => set_state(1);
		return <span><button className="btn btn-secondary form-control-sm" onClick={do_click}>Change password</button><span className="warning">{error}</span></span>;
	} else if (state === 1) {
		let is_disabled = old_password.trim().length === 0 || new_password.trim().length === 0 || new_password !== new_password_again;
		let do_pw = () => {
			post_new_password(old_password, new_password).then(r => {
				do_close();
			}).catch(r => {
				do_close();
				set_error("Wrong password");
			});
		};
		let do_cancel = () => {
			set_state(0);
		};
		return (
			<span>
			<input type="password" placeholder="Old password" value={old_password} onChange={mk_change(set_old_password)}/>
			<input type="password" placeholder="New password" value={new_password} onChange={mk_change(set_new_password)}/>
			<input type="password" placeholder="New password again" value={new_password_again} onChange={mk_change(set_new_password_again)}/>
			<button className="btn btn-primary" disabled={is_disabled} onClick={do_pw}>Set password</button>
			<button className="btn btn-secondary" onClick={do_cancel}>Cancel</button>
			</span>
		);
	}
};

const Header = (props) => {
	return (
		<div className="pb-3">
			<LogOut/>
			<ChangePassword/>
		</div>
	);
};

const Select = (props) => {
	return (
		<div className="p-4">
			<Header/>
			<div>
				<h1>Traks/Files</h1>
				<Intrinfos/>
			</div>
			<div>
				<h1>Traks/Projects</h1>
				<Trinfos/>
			</div>
			<div>
				<h1 className="py-2">BigText</h1>
				<BigTexts/>
			</div>
		</div>
	);
};

export default Select;
