import React from "react";
import Routish from './Routish';
import Auth from './Auth';
import Select from './Select';
import { TrinfoEditor, TrinfoCommitStatus } from './TrinfoEditor';
import BigTextEditor from './BigTextEditor';
import { get_me } from './me';

function App() {
	let def = get_me() ? "select" : "auth";
	return (
		<div>
			<Routish.Outer default={def}>
				<Routish.Inner id="auth">
					<Auth/>
				</Routish.Inner>

				<Routish.Inner id="select">
					<Select/>
				</Routish.Inner>

				<Routish.Inner id="trinfo_editor">
					<TrinfoEditor/>
				</Routish.Inner>

				<Routish.Inner id="trinfo_commit_status">
					<TrinfoCommitStatus/>
				</Routish.Inner>

				<Routish.Inner id="big_text_editor">
					<BigTextEditor/>
				</Routish.Inner>
			</Routish.Outer>
		</div>
	);
}

export default App;
