import React, { useContext, useState, Profiler, useEffect, useRef } from "react";
import Routish from './Routish';
import { classes } from './util';

const Toggle = (props) => {
	return (
		<label>
			{props.children}
			<input
				type="checkbox"
				checked={props.value}
				onChange={e => props.set(e.target.checked)}
			/>
		</label>
	);
};

const A = (props) => {
	let cls, click;
	if (!props.disabled) {
		cls = classes("link", props.className);
		click = props.onClick || (()=>{})
	} else {
		cls = props.className;
		click = (()=>{})
	}
	return <span className={cls} onClick={click}>{props.children}</span>;
};

const AR = (props) => {
	const ctx = useContext(Routish.Context);
	const click = () => ctx.goto_id(props.link, props.arg);
	return <A {...props} onClick={click}>{props.children}</A>;
};

const Loading = (props) => {
	return <span>...</span>;
}

// profiler thing; not very useful, but it can report how long its children
// take to render
function Prof(props) {
	const callback = (id, phase, actual_duration, base_duration, start_time, commit_time, interactions) => {
		console.log({id, phase, actual_duration, base_duration, start_time, commit_time, interactions});
	};
	return (
		<Profiler id="MyProfiler" onRender={callback}>
			{props.children}
		</Profiler>
	);
}

const useInterval = (callback, delay) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => savedCallback.current();
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

const DeleteButton = (props) => {
	let [ state, set_state ] = useState(0);
	if (state === 0) {
		const do_confirmation = () => set_state(1);
		return <button className="btn btn-sm btn-danger ms-1" onClick={do_confirmation}>DELETE</button>
	} else if (state === 1) {
		const do_cancel = () => set_state(0);
		const do_delete = () => { props.on_delete(); set_state(0); }
		return <span>Confirm deletion: <button className="btn btn-danger" onClick={do_delete}>Yes, delete!</button> or <button className="btn btn-secondary" onClick={do_cancel}>No, cancel</button></span>;
	}
};


export {
	Toggle,
	A,
	AR,
	Loading,
	Prof,
	useInterval,
	DeleteButton,
}
