// (trinfo: TRaks INterchange/INtermediate FOrmat)

import React, { useState, useEffect, useCallback } from "react";
import './App.css';
import { array2set, deep_copy, has_value, get_language_icon_svg, download } from './util';

import { DndProvider } from "react-dnd";
import {
	Tree,
	MultiBackend,
	getBackendOptions
} from "@minoru/react-dnd-treeview";

import svg_folder_plus from './folder-plus.svg';
import svg_folder_minus from './folder-minus.svg';
import svg_code from './code-square.svg';
import svg_text from './card-text.svg';
import { Toggle, Loading, A, useInterval } from './components';
import {
	get_trinfo_target,
	post_trinfo_commit,
	get_trinfo_commit_status,
	get_intrinfo_target,
	post_intrinfo_patch
} from './API';

// NOTE: found in `react-dom/src/shared/omittedCloseTags.js`, which seems to
// not be exported to the outside?
const tag_leaf_set = array2set([ "area", "base", "br", "col", "embed", "hr", "img", "input", "keygen", "link", "meta", "param", "source", "track", "wbr" ]);

const LangIcon = (props) => {
	let src = get_language_icon_svg(props.lang);
	if (src === null) return null;
	const sz = 10;
	return <img alt={props.lang} className="traks-lang-icon position-absolute top-50 start-0 translate-middle" width={sz} height={sz} src={src}/>
};

const is_simple_tag = (() => {
	const chr = s => s.charCodeAt(0);
	const aa = chr("a");
	const zz = chr("z")
	return (s) => {
		if (s.length === 0) return false;
		const cc = chr(s);
		return aa <= cc && cc <= zz;
	}
})();

function render_node(node) {
	const n0 = node[0];
	if (typeof n0 === "object") {
		let key = 0;
		return node.map(n => <React.Fragment key={key++}>{render_node(n)}</React.Fragment>);
	} else if (n0 === "TEXT") {
		return <>{node[1]}</>;
	} else if (n0 === "TAG") {
		let C = null;
		if (is_simple_tag(node[1])) {
			C = node[1];
		} else {
			C = (props) => <div className="placeholder-component">{props.children}</div>;
		}
		return <C>{render_node(node[3])}</C>;
	} else if (n0 === "EXPR") {
		return <span className="nodelabel-sourcecode">{node[1]}</span>
	} else if (!n0) {
		return null;
	} else {
		throw new Error("unhandled node type");
	}
}

function get_node_text(node) {
	const tag_repr = (node) => {
		const tag = node[1];
		return "<" + tag + ">...</" + tag + ">";
	};
	switch (node[0]) {
	case "TEXT": return node[1];
	case "EXPR": return node[1];
	case "TAG":  return tag_repr(node);
	default: return "???";
	}
}

const convert_external_to_internal_tree_data = (node) => {
	let xs = [];

	let next_id = (() => {
		let counter = 0;
		return () => ++counter;
	})();

	let push_node; push_node = (node, parent_id) => {
		const n0 = node[0];
		if (typeof n0 === "object") {
			for (let i = 0; i < node.length; i++) push_node(node[i], parent_id);
		} else if (n0 === "TEXT") {
			xs.push({
				id: next_id(),
				parent: parent_id,
				text: get_node_text(node),
				data: {n: node},
			});
		} else if (n0 === "TAG") {
			let id = next_id();
			xs.push({
				id,
				parent: parent_id,
				droppable: !tag_leaf_set[node[1]],
				text: get_node_text(node),
				data: {n: node.slice(0,3)},
			});
			push_node(node[3], id);
		} else if (n0 === "EXPR") {
			xs.push({
				id: next_id(),
				parent: parent_id,
				text: get_node_text(node),
				data: {n: node},
			});
		} else if (!n0) {
			return null;
		} else {
			throw new Error("unhandled node type");
		}
	};

	push_node(node, 0);

	return xs;
};

const convert_internal_to_external_tree_data = (xs) => {
	let handle_parent_id; handle_parent_id = (pid) => {
		let ys = [];
		for (const x of xs) {
			let { id, parent, data } = x;
			if (parent !== pid) continue;
			let node = [...data.n];
			if (node[0] === "TAG") node.push(handle_parent_id(id));
			ys.push(node);
		}
		return ys;
	};
	return handle_parent_id(0);
};

const TreeNode = (props) => {
	let { node, is_open, on_toggle, on_select, selected } = props;

	let src = null;
	let pprops = {};
	let label = null;
	const n = node.data.n;
	switch (n[0]) {
	case "TAG":
		let is_leaf = tag_leaf_set[n[1]];
		src = is_leaf ? (svg_code) : (is_open ? svg_folder_minus : svg_folder_plus);
		pprops.onClick = is_leaf ? undefined : on_toggle;
		pprops.className = is_leaf ? "" : "treeview-toggle";
		pprops.title = "Tag";
		label = <span className="nodelabel-tag">{get_node_text(n)}</span>;
		break;
	case "EXPR":
		src = svg_code;
		label = <span className="nodelabel-sourcecode">{get_node_text(n)}</span>;
		pprops.title = "Expression node";
		break;
	case "TEXT":
		src = svg_text;
		pprops.title = "Text";
		label = <span className="nodelabel-text">{get_node_text(n)}</span>;
		break;
	default: return null;
	}

	let icon = <img src={src} alt={node.data.n[0]} {...pprops}/>

	let cls = ["node"];
	if (selected) cls.push("selected");

	const do_select = () => {
		on_select(node.id);
	}

	return (
		<div className={cls.join(" ")} onClick={do_select}>{icon} {label}</div>
	);
};

const TextInputWithCommit = (props) => {
	let pprops = {...props};
	delete pprops.onCommit;

	// commit when losing focus
	const on_blur = (e) => props.onCommit();

	// commit when pressing [ENTER]
	const on_keydown = (e) => {
		if (e.key === "Enter") {
			props.onCommit();
			e.preventDefault();
		}
	};

	return (
		<input
			type="text"
			onBlur={on_blur}
			onKeyDown={on_keydown}
			{...pprops}
		/>
	);
};

function split_whitespace(s) {
	const n_leading =  s.length - s.trimStart().length;
	const n_trailing = s.length - s.trimEnd().length;
	const n_middle = s.length - (n_leading + n_trailing);

	const i0 = 0;
	const i1 = i0 + n_leading;
	const i2 = i1 + n_middle;
	const i3 = i2 + n_trailing;

	return [
		s.slice(i0, i1),
		s.slice(i1, i2),
		s.slice(i2, i3),
	];
}

const TextEdit = (props) => {
	let [ p_leading_whitespace, p_text, p_trailing_whitespace ] = split_whitespace(props.text);
	let [ preserve_whitespace, set_preserve_whitespace ] = useState(true);
	let [ text, set_text ] = useState(preserve_whitespace ? p_text : props.text);

	useEffect(() => {
		set_text(preserve_whitespace ? p_text : props.text);
	}, [props.text]);

	const do_commit = () => {
		props.on_update(preserve_whitespace ? p_leading_whitespace+text+p_trailing_whitespace : text);
	}

	const update_preserve_whitespace = (p) => {
		if (p) {
			set_text(old_text => old_text.trim());
		} else {
			set_text(old_text => p_leading_whitespace+old_text+p_trailing_whitespace);
		}
		set_preserve_whitespace(p);
	};

	return (
		<div className="form-group">
			<TextInputWithCommit type="text" className="form-control" value={text} onChange={e=>set_text(e.target.value)} onCommit={do_commit}/>
			<label>
				<input
					type="checkbox"
					className="form-check-input"
					checked={preserve_whitespace}
					onChange={e=>update_preserve_whitespace(e.target.checked)}
				/>
				Hide leading/trailing whitespace
			</label>
		</div>
	);
};

const NodeEditor = (props) => {
	let { node, on_update, collected_stubs, is_patched } = props;

	const [ tree_data, set_tree_data ] = useState(convert_external_to_internal_tree_data(node));
	const [ selected_node_id, set_selected_node_id ] = useState(null);

	useEffect(() => {
		set_tree_data(convert_external_to_internal_tree_data(node));
		set_selected_node_id(null);
	}, [node]);

	const push_tree = (new_tree) => {
		set_tree_data(new_tree);
		on_update(convert_internal_to_external_tree_data(new_tree));
	};

	const handle_drop = (new_tree) => push_tree(new_tree);

	const render_node = (node, o) => (
		<TreeNode
			node={node}
			depth={o.depth}
			is_open={o.isOpen}
			on_toggle={o.onToggle}
			selected={node.id === selected_node_id}
			on_select={(node_id) => set_selected_node_id(node_id)}
		/>
	);
	const render_placeholder = () => null;

	const can_drop = (tree, o) => {
		let { dragSource, dropTargetId } = o;
		if (dragSource && dragSource.parent === dropTargetId) {
			return true;
		}
	};

	let adder_ctrls = [];

	const add_node_adder = (key, contents, builder) => {
		const on_click = () => {
			const node = builder();
			let max_id = 0;
			for (const x of tree_data) if (x.id > max_id) max_id = x.id;
			const new_id = max_id + 1;
			push_tree([...tree_data, {
				id: new_id,
				parent: 0,
				text: get_node_text(node),
				data: {n: node},
			}]);
		};
		adder_ctrls.push(
			<button key={key} className="btn btn-secondary adder-btn form-control-sm m-1" onClick={on_click}>+{contents}</button>
		);
	};

	add_node_adder("TEXT", <img src={svg_text} alt="TEXT"/>, () => {
		return ["TEXT", "Edit me!"];
	});

	for (let s of collected_stubs) {
		((s) => {
			let {key,stub} = s;
			let src, label;
			switch (stub[0]) {
			case "TAG":
				src = tag_leaf_set[stub[1]] ? svg_code : svg_folder_plus;
				label = <span className="nodelabel-tag">{get_node_text(stub)}</span>;
				break;
			case "EXPR":
				src = svg_code;
				label = <span className="nodelabel-sourcecode">{get_node_text(stub)}</span>;
				break;
			default:
				break;
			}

			add_node_adder(key, <><img src={src} alt={stub[0]}/> {label}</>, () => {
				return stub;
			});
		})(s);
	}

	let edit_ctrls = [];

	let do_revert, do_delete;

	const set_do_delete = (id) => {
		do_delete = () => {
			let new_tree = [];
			for (const n of tree_data) {
				if (n.id !== id) {
					new_tree.push(n);
				}
			}
			on_update(convert_internal_to_external_tree_data(new_tree), true);
		};
	}

	if (selected_node_id) {
		for (const n of tree_data) {
			if (n.id !== selected_node_id) continue;
			if (n.data.n[0] === "TEXT") {
				((id) => {
					const on_text_update = (new_text) => {
						let new_tree = [];
						for (const n of tree_data) {
							if (n.id !== id) {
								new_tree.push(n);
							} else {
								new_tree.push({
									...n,
									data: {
										...n.data,
										n: ["TEXT", new_text],
									},
								});
							}
						}
						on_update(convert_internal_to_external_tree_data(new_tree), true);
					};
					edit_ctrls.push(
						<TextEdit key="text-edit" text={n.data.n[1]} on_update={on_text_update}/>
					);
				})(n.id);
			}
			set_do_delete(n.id);
			break;
		}
	}

	if (is_patched) {
		do_revert = () => on_update(null, true);
	}

	return (
		<div>
			<div className="mt-3">
				<Tree
					tree={tree_data}
					rootId={0}
					render={render_node}
					onDrop={handle_drop}
					classes={{
						root: "treeview-root",
						draggingSource: "treeview-dragging-source",
						placeholder: "treeview-placeholder",
					}}
					sort={false}
					insertDroppableFirst={false}
					canDrop={can_drop}
					dropTargetOffset={5}
					placeholderRender={render_placeholder}
					initialOpen={true}
				/>
			</div>
			<div className="form-group">
				{adder_ctrls}
				<div>
				<button disabled={!do_revert} className="btn btn-warning form-control-sm" onClick={do_revert}>Revert edits</button>
				<button disabled={!do_delete} className="btn btn-danger form-control-sm"  onClick={do_delete}>Delete selected item</button>
				</div>
				{edit_ctrls}
			</div>
		</div>
	);
};


const PAGE_SIZE = 10;

const Editor1 = (props) => {
	let keys = [];

	let [ selected_ukey, set_selected_ukey ] = useState(null);
	let [ selected_node, set_selected_node ] = useState(null);
	let { patch, push_patch } = props;

	let collected_stubs = null;
	let selected_node_is_patched = false;

	const set_selection = (ukey, node) => {
		if (ukey === selected_ukey) return;
		set_selected_ukey(ukey);
		set_selected_node(node);
	};

	const collect_stubs = (t0) => {
		let xs = [];
		let stub_set = {};
		const add_stub = (stub) => {
			let key = JSON.stringify(stub);
			if (stub_set[key]) return;
			stub_set[key] = true;
			xs.push({key,stub});
		};
		let collect; collect = (n) => {
			let n0 = n[0];
			if (typeof n0 === "object") {
				for (let nn of n) collect(nn);
			} else if (n0 === "TEXT") {
				// ignore
			} else if (n0 === "TAG") {
				add_stub(n.slice(0,3));
				collect(n[3]);
			} else if (n0 === "EXPR") {
				add_stub(n);
			} else if (!n0) {
				// ignore
			} else {
				throw new Error("unhandled node type");
			}
		};
		for (const t1 of t0.translations) {
			for (const t2 of t1.nodes) {
				collect(t2.node);
			}
		}
		return xs;
	};

	// big title reused a lot:
	const new_checkbox_title = `
	The ''new''-flag only affects how translations are filtered in this
	editor (try the New/Old/Deleted checkboxes in the topbar), and changes
	will only have an effect if you commit them and continue editing at a
	later time. When all translations are done, the ''new''-flag should be
	cleared, because all work is done on this translation. But you may want
	to set/clear it anyway, for your own purposes, to show/hide it from the
	list of ''new'' translations.
	`.replace(/[ \r\n\t]+/g, " ").trim(); // and "unformat" a bit

	let node_selected_by_ukey = null;
	let selection_is_seen = false;
	for (const t0 of props.translations) {
		let langs_and_stuff = [];
		for (const t1 of t0.translations) {
			let nodes = [];
			for (const i2 in t1.nodes) {
				const ukey = [t0.key, t1.lang, i2].join("/");
				const t2 = t1.nodes[i2];
				let is_patched, node;
				if (patch[ukey] && JSON.stringify(patch[ukey]) !== JSON.stringify(t2.node)) {
					node = patch[ukey];
					is_patched = true;
				} else {
					node = t2.node;
					is_patched = false;
				}
				if (ukey === selected_ukey) {
					selection_is_seen = true;
					node_selected_by_ukey = node;
					collected_stubs = collect_stubs(t0);
					selected_node_is_patched = is_patched;
				}
				((ukey, is_patched, node) => {
					let cls = "traks-node";
					if (ukey === selected_ukey) {
						cls += " selected";
					}
					let prefix = null;
					if (is_patched) {
						prefix = <span className="patched">&bull;</span>;
					}
					nodes.push(
						<div key={i2} className={cls} onClick={() => set_selection(ukey, node)}>
							{prefix}
							{render_node(node)}
						</div>
					);
				})(ukey, is_patched, deep_copy(node));
			}
			langs_and_stuff.push(
				<div key={t1.lang} className="traks-lang position-relative" title={t1.lang + " (language)"}>
					<LangIcon lang={t1.lang}/>
					{nodes}
				</div>
			);
		}

		{
			const mk_newkey = (k) => [k,"NEW"].join("/");

			let is_new = patch[mk_newkey(t0.key)];
			if (is_new === undefined) is_new = t0.is_new;

			let on_change_new = ((key) => (e) => {
				push_patch(mk_newkey(key), !!e.target.checked);
			})(t0.key);

			langs_and_stuff.push(
				<div key="CONTROLS" className="traks-lang position-relative" title="Controls">
					<span className="traks-lang-icon position-absolute top-50 start-0 translate-middle" width={10} height={10}>&#9998;</span>
					<div className="traks-node traks-but-not-a-node-actually">
						<label title={new_checkbox_title}>New <input
							title={new_checkbox_title}
							type="checkbox"
							checked={is_new}
							onChange={on_change_new}
						/></label>
					</div>
				</div>
			);
		}


		let cls = "traks-key";
		if (t0.is_deleted) {
			cls += " deleted";
		} else if (t0.is_new) {
			cls += " new";
		}
		keys.push(
			<div key={t0.key} className={cls} title={t0.key + " (internal traks key)"}>
				{langs_and_stuff}
			</div>
		);
	}

	const on_update = useCallback((new_value, do_propagate) => {
		push_patch(selected_ukey, new_value);
		if (do_propagate) {
			if (new_value !== null) {
				set_selected_node(new_value);
			} else {
				set_selected_node(deep_copy(node_selected_by_ukey));
			}
		}
	}, [ selected_ukey ]);

	let editor;
	if (!selection_is_seen) {
		editor = <div>&lArr; Select an item</div>;
	} else {
		editor = <NodeEditor
			node={selected_node}
			collected_stubs={collected_stubs}
			on_update={on_update}
			is_patched={selected_node_is_patched}
		/>;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-5 keylist">
				{keys}
				</div>
				<div className="col-7 position-sticky overflow-auto top-0 h-100">
				{editor}
				</div>
			</div>
		</div>
	);
};

const PaginatorControl = (props) => {
	const prev = () => props.set_page(props.page - 1);
	const next = () => props.set_page(props.page + 1);
	return (
		<span className="noselect">
		<A onClick={prev} disabled={props.page === 0}>«</A>
		{props.page}/{props.max_page}
		<A onClick={next} disabled={props.page >= props.max_page}>»</A>
		</span>
	);
};

const FilterControl = (props) => {
	const change = (e) => {
		props.set_filter(e.target.value);
	};
	return (
		<input placeholder="Search" value={props.filter} onChange={change}/>
	);
};

function TrinfoEditor(props) {
	const arg_type = props.arg.type;
	const arg_key  = props.arg.key;

	let [ show_new, set_show_new ] = useState(true);
	let [ show_old, set_show_old ] = useState(false);
	let [ show_deleted, set_show_deleted ] = useState(false);
	let [ data, set_data ] = useState(null);
	let [ page, set_page ] = useState(0);
	let [ filter, set_filter ] = useState("");
	let [ patch, set_patch ] = useState({});

	const set_filter_ex = (f) => {
		set_filter(f);
		set_page(0);
	};

	let data_map = {};
	if (has_value(data)) {
		for (const t0 of data.list) {
			data_map[[t0.key,"NEW"].join("/")] = t0.is_new;
			for (const t1 of t0.translations) {
				for (const i2 in t1.nodes) {
					const ukey = [t0.key, t1.lang, i2].join("/");
					const node = t1.nodes[i2].node;
					data_map[ukey] = node;
				}
			}
		}
	}

	const push_patch = (ukey, value) => {
		set_patch((old_patch) => {
			if (has_value(value) && has_value(data) && JSON.stringify(value) !== JSON.stringify(has_value(data_map[ukey]) ? data_map[ukey] : null)) {
				return {...old_patch, [ukey]: value};
			} else {
				let p = {...old_patch};
				delete p[ukey];
				return p;
			}
		})
	};

	useEffect(() => {
		if (arg_type === "trinfo") {
			get_trinfo_target(arg_key).then(e => {
				set_data(e.data);
			});
		} else if (arg_type === "intrinfo") {
			get_intrinfo_target(arg_key).then(e => {
				set_data(e.data.original);
				if (e.data.patch !== null) {
					let patch = {};
					for (const t0 of e.data.patch.list) {
						for (const t1 of t0.translations) {
							for (const i in t1.nodes) {
								const ukey = t0.key + "/" + t1.lang + "/" + i;
								patch[ukey] = t1.nodes[i];
							}
						}
					}
					set_patch(patch);
				}
			});
		} else {
			throw new Error("unhandled type: " + arg_type);
		}
	}, [arg_key]);

	let max_page = 0;
	let editor;
	if (data) {
		const fs = filter.split(" ").filter(x => (x.length > 0)).map(x => x.toLocaleLowerCase());

		let extract_node_text; extract_node_text = (n) => {
			const n0 = n[0];
			if (typeof n0 === "object") {
				return n.map(extract_node_text).join(" ");
			} else if (n0 === "TEXT") {
				return n[1].toLocaleLowerCase();
			} else if (n0 === "TAG") {
				return extract_node_text(n[3])
			}
		};

		let extract_translations_text = (ts) => {
			let xs = [];
			for (const tt of ts) {
				for (const n of tt.nodes) {
					xs.push(extract_node_text(n.node));
				}
			}
			return xs.join(" ");
		};

		let translations = data.list.filter(t => {
			if (t.is_deleted) {
				if (!show_deleted) return false;
			} else if (t.is_new) {
				if (!show_new) return false;
			} else {
				if (!show_old) return false;
			}

			if (fs.length > 0) {
				const text = extract_translations_text(t.translations)
				for (const f of fs) {
					if (text.indexOf(f) === -1) return false;
				}
			}

			return true;
		});
		max_page = Math.floor((translations.length-1) / PAGE_SIZE);
		translations = translations.slice(page*PAGE_SIZE, (page+1)*PAGE_SIZE);
		editor = <Editor1 translations={translations} patch={patch} push_patch={push_patch}/>;
	} else {
		editor = <Loading/>;
	}

	let is_dirty = Object.keys(patch).length > 0;

	const collect_payload = () => {
		// convert `patch` into trinfo format
		let list = [];
		let keymap = {};
		for (let ukey in patch) {
			let value = patch[ukey];
			let [ key, lang_or_new, index ] = ukey.split("/");
			index = parseInt(index, 10);
			let o = keymap[key];
			if (o === undefined) {
				o = {
					key,
					translations: [],
				};
				list.push(o);
				keymap[key] = o;
			}

			if (lang_or_new === "NEW") {
				o.is_new = value;
			} else {
				let lang = lang_or_new;
				let t = null;
				for (let tt of o.translations) {
					if (tt.lang === lang) {
						t = tt;
						break;
					}
				}

				if (t === null) {
					t = {
						lang,
						nodes: [],
					}
					o.translations.push(t);
				}

				let n = t.nodes[index];
				if (n === undefined) n = value;
				t.nodes[index] = n;
			}
		}
		return { list };
	};

	const do_trinfo_commit = () => {
		if (arg_type !== "trinfo") throw new Error("type/usage mismatch");
		const payload = collect_payload();
		if (window.confirm("Are you sure you want to commit your changes?")) {
			post_trinfo_commit(arg_key, payload).then(e => {
				props.goto_id("trinfo_commit_status", e.data.StatusKey);
			}).catch(e => {
				console.log(e);
				alert("FEHLER");
			});
		}
	};
	const do_discard = () => {
		const leave = () => props.goto_id("select");
		if (!is_dirty) {
			leave();
		} else {
			if (window.confirm("Are you sure you want to discard your changes?")) {
				leave();
			}
		}
	};

	const do_intrinfo_save = () => {
		const payload = collect_payload();
		post_intrinfo_patch(arg_key, payload).then(e => {
			alert("Your changes were saved; a developer must now import your changes");
			props.goto_id("select");
		}).catch(e => {
			console.log(e);
			alert("FEHLER/2");
		});
	};

	// debug interface
	window.DEBUG = {
		get_payload: () => collect_payload(),
		get_payload_json: () => JSON.stringify(collect_payload()),
		print_payload_json: () => console.log(JSON.stringify(collect_payload())),
		download: () => download("patch.json", JSON.stringify(collect_payload())),
		test_commit_status: () => props.goto_id("trinfo_commit_status", "TEST"),
	};

	const context = (arg_type === "trinfo") ? "Project" : (arg_type === "intrinfo") ? "File" : "???";

	let save_button;
	if (arg_type === "trinfo") {
		save_button = <button className="btn btn-primary   p-1 m-1 form-control-sm" disabled={!is_dirty} onClick={do_trinfo_commit}>Commit</button>
	} else if (arg_type === "intrinfo") {
		save_button = <button className="btn btn-primary   p-1 m-1 form-control-sm" disabled={!is_dirty} onClick={do_intrinfo_save}>Save</button>
	} else {
		save_button = <span>???</span>;
	}

	return (
		<DndProvider backend={MultiBackend} options={getBackendOptions()}>
			<nav className="navbar our-topbar">
				<div className="mr-auto form-group">
					<PaginatorControl page={page} max_page={max_page} set_page={set_page}/>
					<FilterControl filter={filter} set_filter={set_filter_ex}/>
					<Toggle value={show_new} set={set_show_new}>New</Toggle>
					<Toggle value={show_old} set={set_show_old}>Old</Toggle>
					<Toggle value={show_deleted} set={set_show_deleted}>Deleted</Toggle>
					{save_button}
					<button className={"btn p-1 m-1 form-control-sm " + (is_dirty ? "btn-danger" : "btn-secondary")} onClick={do_discard}>{is_dirty ? "Discard and go back to Select" : "Go back to select"}</button>
					<span className="trinfo-editing">Editing <tt>{arg_key}</tt> ({context})</span>
				</div>
			</nav>
			{editor}
		</DndProvider>
	);
}

const TrinfoCommitStatus = (props) => {
	let [ commit_status, set_commit_status ] = useState(null);
	useInterval(() => {
		get_trinfo_commit_status(props.arg).then(e => {
			console.log(["OK RESPONSE", e]);
			set_commit_status(e.data);
		}).catch(e => {
			console.log(["FAILED RESPONSE", e]);
			set_commit_status("CANNOT READ COMMIT STATUS FROM API");
		});
	}, 3*1000);

	let st = <>...</>;
	if (commit_status !== null) {
		if (typeof commit_status === "object") {
			let t = "???";
			if (commit_status.Working) {
				t = "Working...";
			} else if (commit_status.Failed) {
				t = "FAILED";
			} else if (commit_status.Done) {
				t = "Done";
			}
			st = (
				<>
				<div>Payload Key: {commit_status.PayloadKey}</div>
				<div>Status: {t}</div>
				</>
			);
		} else {
			console.log(["ERROR", commit_status]);
			st = (
				<>
				<div>ERROR: {commit_status}</div>
				</>
			);
		}
	}

	return (
		<div>
			<p>
			Your translations are now being processed. This is
			typically a 2-step procedure; the first step merges
			your translations into the codebase, whereas the second
			step builds and publishes the live site. The status
			below only reflects the status of the first step, so
			please allow for additional time to pass (5-15 minutes)
			before expecting to see your changes on the live site.
			</p>

			<p>
			If the first step fails, it means that we could not
			merge your translations back into the code. This can
			happen if other people made changes concurrently with
			you (or if there are bugs). But don't despair; send the
			"Payload Key" to an Opus EDB developer; then we can
			attempt to salvage your work (however, there's a 7-day
			time limit on this, so don't wait too long). If you
			lost the Payload Key, we can probably find it anyway.
			Or: if your edits are small and trivial, you can simply
			try again.
			</p>

			<p>{st}</p>
		</div>
	);
};

export {
	TrinfoEditor,
	TrinfoCommitStatus,
}
