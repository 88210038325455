import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { get_me, set_me } from './me';
import { get_auth } from './API';

function boot() {
	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(<React.StrictMode><App/></React.StrictMode>);
}

let me = get_me();
if (me) {
	get_auth(me).then(me => {
		boot();
	}).catch(e => {
		set_me(null);
		boot();
	});
} else {
	boot();
}
