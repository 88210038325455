import React, { useState, useContext, useEffect, useRef } from "react";
import Routish from './Routish';
import axios from 'axios';
import { Loading } from './components';
import { post_big_text } from './API';

// ckeditor's constructor is asynchronous; ClassicEditor.create() returns a
// Promise. This makes it hard to construct in useEffect()'s, because cleanup
// functions tend to be called _before_ the Promise resolves. Especially when
// using React.StrictMode, this causes double-construction and crap. This class
// solves that by allowing .destroy() to be called before the Promise resolves,
// in which case the editor will be destroyed immediately when the Promise
// resolves.
class CK {
	constructor(em, contents) {
		this.editor = null;
		this.destroyed = false;
		let opts = {
			licenseKey: '',
			initialData: contents || '',
		};
		window.ClassicEditor.create(em, opts).then((editor) => {
			if (this.destroyed) {
				editor.destroy();
				this.editor = null;
			} else {
				this.editor = editor;
			}
		}).catch((err) => {
			console.log(err);
			alert("ckeditor init failed");
		});
	}

	destroy() {
		if (this.destroyed) return;
		this.destroyed = true;
		if (this.editor) this.editor.destroy();
		this.editor = null;
	}
}

const Editor = (props) => {
	const router = useContext(Routish.Context);

	const [ last_save, set_last_save ] = useState(props.original);

	let ref = useRef(null);
	let [ ck, set_ck ] = useState(null);

	const has_ck = () => !!ck && !!ck.editor; // ck.editor is constructed asynchronously

	useEffect(() => {
		if (!ref || !ref.current) return;
		let ck = new CK(ref.current, props.original);
		set_ck(ck);
		return () => ck.destroy();
	}, [ref, props.original]);

	const get_html = () => ck.editor.getData();

	const do_save = () => {
		if (!has_ck()) return;
		let html = get_html();
		props.on_save(html);
		set_last_save(html);
	};

	const is_dirty = () => {
		if (!has_ck()) return;
		return get_html() !== last_save;
	};

	const do_cancel = () => {
		const really_do_cancel = () => router.goto_id("select");
		if (is_dirty()) {
			if (window.confirm("Are you sure? The changes you have made will be lost!")) {
				really_do_cancel();
			}
		} else {
			really_do_cancel();
		}
	};

	return (
		<>
		<div className="row">
			<div className="col">
				<button className="btn btn-primary" onClick={do_save}>Save</button>
				<button className="btn btn-secondary ms-1" onClick={do_cancel}>Go back</button>
			</div>
		</div>
		<div className="row mt-2">
			<div className="col">
				<div className="editor-root" ref={ref}/>
			</div>
		</div>
		</>
	);
};

const BigTextEditor = (props) => {
	let { arg } = props;
	let project = arg.project;
	let [ key, language ] = arg.key;
	let original_url = arg.fetch_url_prefix + "/b/" + project + "/b." + key + "." + language + ".html";
	let [ original, set_original ] = useState(null);
	let [ warning, set_warning ] = useState(null);

	const could_not_fetch_original_text = (response) => {
		console.log(response);
		set_warning("Warning: could not fetch original text (saving will overwrite original, if it actually exists)");
		set_original(""); // still allow editing
	};

	useEffect(() => {
		axios.get(original_url, { responseType: 'text', params: {v: ""+Date.now()}}).then(x => {
			if (x.status === 200) {
				set_original(x.data);
			} else {
				could_not_fetch_original_text(x);
			}
		}).catch(x => {
			could_not_fetch_original_text(x);
		});
	}, [original_url]);

	let warning_em = null;
	if (warning) warning_em = <span className="warning">{warning}</span>;

	let editor = <Loading/>;
	if (original !== null) {
		const on_save = (body) => {
			post_big_text(project, key, language, body).then(e => {
				alert("saved!");
			}).catch(e => {
				alert("FAILED");
			});
		};
		editor = <Editor original={original} on_save={on_save}/>;
	}

	return (
		<>
		<div className="container">
			<div className="row">
				<div>
					<h2><tt>{project}:{key}:{language}</tt></h2>
					{warning_em}
				</div>

				<div className="helpful-note">
					Guidelines on inserting links:
					<ul>

					<li>When linking to pages on
					the same site, please use relative paths, not full URLs
					(e.g. <tt>/path/to/page</tt> instead of <tt>https://zevio.com/path/to/page</tt>.</li>

					<li>When inserting links, include the correct language
					in the URL where appropriate. E.g. on zevio, to link to
					the privacy page, use <tt>/{language}/privacy</tt>, not <tt>/privacy</tt>.</li>

					</ul>
				</div>
			</div>

			{editor}
		</div>
		</>
	);
};

export default BigTextEditor;

