const me_key = "me";

const get_me = () => {
	let me_item = window.localStorage.getItem(me_key);
	if (me_item === null) {
		return null;
	} else {
		return JSON.parse(me_item);
	}
};

const must_get_me = () => {
	let me = get_me();
	if (!me) throw new Error("expected to have identity");
	return me;
};

const set_me = (me) => {
	if (!me) {
		window.localStorage.removeItem(me_key);
	} else {
		window.localStorage.setItem(me_key, JSON.stringify(me));
	}
};

export {
	get_me,
	must_get_me,
	set_me,
};
